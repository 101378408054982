import { defineStore } from 'pinia';
import eventDispatcher from '@/stores/event-dispatcher';

import {
  create as apiCreate,
  del as apiDelete,
  get as apiGet,
  getAll as apiGetAll
} from '@/api/endpoints/comment';
import i18n from '@/i18n/index';
import { getSince } from '@/utils/date';
import { track } from '@/utils/tracking';
import {
  COMMENT_TYPE,
  BOARD_TYPE,
  FOLDER_TYPE,
  CARD_TYPE,
  MEDIA_TYPE,
  SHARE_ASSET_TYPE
} from '@/utils/types';
import { useAuthStore } from '@/stores/auth';

const endpoints = {
  [BOARD_TYPE]: '/boards',
  [FOLDER_TYPE]: '/folders',
  [CARD_TYPE]: '/cards',
  [MEDIA_TYPE]: '/medias',
  [SHARE_ASSET_TYPE]: '/share-assets'
};

const initialState = {
  loading: 0,
  current: null,
  parent: null,
  comments: [],
  hasNextPage: false,
  sort: { creationDate: 'desc' }
};

const map = (item) => {
  item.creationDate = new Date(item.creationDate);
  const since = getSince(item.creationDate);
  return { ...item, since: i18n.global.t(`since.${since.type}`, since.value) };
};

export const key = COMMENT_TYPE;
export const useCommentStore = defineStore(key, {
  state: () => ({ ...initialState }),
  actions: {
    async reset() {
      const { loading } = this;
      Object.assign(this, { ...initialState });
      this.loading = loading;
    },
    async startLoading() {
      this.loading++;
    },
    async stopLoading() {
      this.loading--;
    },
    async assetExists({ id }) {
      if (!this.parent) return false;
      return this.parent.id === id || this.parent.item.board?.id === id;
    },
    async refresh({ id, parentId }) {
      if (this.parent?.id !== parentId) return;

      await this.startLoading();

      try {
        const item = await apiGet(
          endpoints[this.parent.type],
          parentId,
          id,
          useAuthStore().isNamedShareLoggedIn
        );
        const newItem = map(item);

        if (this.comments.some((x) => x.id === newItem.id)) {
          this.comments = this.comments.map((x) =>
            x.id !== newItem.id ? x : newItem
          );
        } else {
          const comments1 = this.comments.filter(
            (x) => x.creationDate <= newItem.creationDate
          );
          const comments2 = this.comments.filter(
            (x) => x.creationDate > newItem.creationDate
          );
          this.comments = [...comments2, newItem, ...comments1];
        }
      } finally {
        await this.stopLoading();
      }
    },
    async add({ ...data }) {
      if (!this.parent) return;

      await this.startLoading();

      try {
        const newItem = await apiCreate(
          endpoints[this.parent.type],
          this.parent.id,
          data,
          useAuthStore().isNamedShareLoggedIn
        );

        let location;
        if (this.parent.type === BOARD_TYPE) {
          location = parent.parent ? 'sub_project_board' : 'project_board';
        } else if (this.parent.type === FOLDER_TYPE) {
          location = FOLDER_TYPE;
        } else if (this.parent.type === CARD_TYPE) {
          location = parent.brief ? 'brief' : CARD_TYPE;
        } else if (this.parent.type === MEDIA_TYPE) {
          location = MEDIA_TYPE;
        } else if (this.parent.type === SHARE_ASSET_TYPE) {
          location = SHARE_ASSET_TYPE;
        }

        if (location) {
          track('comment', {
            time: new Date().toISOString(),
            location_on: location
          });
        }

        this.comments = [map(newItem), ...this.comments];

        return newItem;
      } finally {
        await this.stopLoading();
      }
    },
    async deleteItem(id) {
      if (this.comments.some((x) => x.id === id)) {
        this.comments = this.comments.filter((x) => x.id !== id);
      }
    },
    async remove({ id }) {
      if (!this.parent) return;

      await apiDelete(
        endpoints[this.parent.type],
        this.parent.id,
        id,
        useAuthStore().isNamedShareLoggedIn
      );
      await this.deleteItem(id);
    },
    async getAll(options = {}) {
      const {
        sort,
        parent: { id, type },
        comments
      } = this;
      const { start = comments.length, limit } = options;

      await this.startLoading();

      try {
        const { items, hasNextPage } = await apiGetAll(
          endpoints[type],
          id,
          {
            query: { start, sort, limit }
          },
          useAuthStore().isNamedShareLoggedIn
        );

        const newItems = items.map(map);

        this.comments = start === 0 ? newItems : [...comments, ...newItems];
        this.hasNextPage = hasNextPage;

        if (this.comments.length > 0) {
          const parent = {
            ...this.parent,
            item: { ...this.parent.item, hasUnreadComments: false }
          };
          eventDispatcher.assetUpdated(parent);
        }

        return { items: newItems, hasNextPage };
      } finally {
        await this.stopLoading();
      }
    },
    async setParent(item) {
      await this.reset();
      this.parent = item;
      await this.getAll({ start: 0 });
    },
    async commentDeleted({ parentId, id }) {
      if (this.parent?.id !== parentId) return;
      await this.deleteItem(id);
    },
    async assetUpdated(item) {
      if (!this.parent) return;

      const parent = { ...this.parent };
      if (parent.id === item.id) {
        this.parent = { ...parent, item: item };
      } else if (parent.item.board?.id === item.id) {
        Object.assign(parent.item.board, { private: item.private });
        this.parent = parent;
      }
    },
    async assetDeleted({ id }) {
      if (!this.parent) return;

      if (this.parent.id === id || this.parent.item.board?.id === id) {
        await this.reset();
      }
    }
  }
});

export default {
  key,
  use: useCommentStore
};
